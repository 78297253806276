import { useEffect, useState } from 'react';

import {
  Button,
  DataLoader,
  Icon,
  Modal,
  PageHeader,
  PopOver,
  PopOverMenu,
  Table,
  TableColumn,
} from '@components';
import {
  EstablishmentUnit,
  OpenTimes,
  WeekDays,
} from '@interfaces/EstablishmentUnit';
import { RepositoryParams } from '@services/api';
import { EstablishmentFilters } from '@services/api/establishment';
import EstablishmentUnitAPICaller from '@services/api/establishmentUnit';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { SelectOptions } from '@interfaces/Utils';
import { Convenience } from '@interfaces/Convenience';
import { EstablishmentUnitsForm } from '@pages/EstablishmentPage/EstablishmentUnitsForm';

export default function UnitsPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{
    total: number;
    result: EstablishmentUnit[];
  }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<EstablishmentFilters>({
    joins: ['establishment'],
    order: 'establishment.name',
  });
  const [isOpenEstablishmentUnitModal, setIsOpenEstablishmentUnitModal] =
    useState('');
  const { handleDynamicPermission } = usePermissionContext();
  const confirmation = useConfirmationModal();

  const deleteEstablishmentUnit = (id: string) => {
    confirmation({
      title: 'Excluir unidade',
      description: 'Deseja realmente excluir essa unidade?',
      confirmButtonTitle: 'Excluir',
      onSubmit: () => EstablishmentUnitAPICaller.remove(id),
    });
  };

  const [unit, setUnit] = useState<EstablishmentUnit | null>(null);

  const [expandedId, setExpandedId] = useState('');

  const fetchUnit = async (id: string) => {
    setUnit(null);
    const response = await EstablishmentUnitAPICaller.fetch(id);

    setUnit(response as EstablishmentUnit);
  };

  const list = (filters: RepositoryParams) => {
    setIsLoading(true);
    EstablishmentUnitAPICaller.list(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    list(filters);
  }, [filters]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader title="Unidades" />
      </div>

      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
        className="pointer"
        expandedRow={expandedId}
        onRowClick={(row) => {
          if (expandedId === row.id) {
            setUnit(null);
            setExpandedId('');
          } else {
            setExpandedId(row.id);
            fetchUnit(row.id);
          }
        }}
        renderExpandedRow={(_) => (
          <div className="container mt-s-200">
            <div className="row grid-gap-1">
              <div className="col-md-4">
                <h4>Nome</h4>
                <span>
                  <DataLoader data={unit?.name} isLoading={!Boolean(unit)} />
                </span>
              </div>
              <div className="col-md-4">
                <h4>Status</h4>

                <div className="row d-flex align-items-center">
                  <span>
                    <DataLoader
                      data={unit?.status}
                      isLoading={!Boolean(unit)}
                    />
                  </span>
                  <div
                    className={`little-ball-${
                      unit?.status === 'Ativo' ? 'green' : 'red'
                    }`}
                  ></div>
                </div>
              </div>

              <div className="col-md-4">
                <h4>Instagram</h4>
                <span>
                  <DataLoader
                    data={unit?.instagram}
                    isLoading={!Boolean(unit)}
                  />
                </span>
              </div>

              <div className="col-md-4">
                <h4>CEP</h4>
                <span>
                  <DataLoader data={unit?.zipCode} isLoading={!Boolean(unit)} />
                </span>
              </div>
              <div className="col-md-4">
                <h4>Estado</h4>
                <span>
                  <DataLoader data={unit?.state} isLoading={!Boolean(unit)} />
                </span>
              </div>
              <div className="col-md-4">
                <h4>Cidade</h4>
                <span>
                  <DataLoader data={unit?.city} isLoading={!Boolean(unit)} />
                </span>
              </div>

              <div className="col-md-4">
                <h4>Bairro</h4>
                <span>
                  <DataLoader
                    data={unit?.district}
                    isLoading={!Boolean(unit)}
                  />
                </span>
              </div>
              <div className="col-md-4">
                <h4>Rua</h4>
                <span>
                  <DataLoader data={unit?.street} isLoading={!Boolean(unit)} />
                </span>
              </div>
              <div className="col-md-4">
                <h4>Número</h4>
                <span>
                  <DataLoader data={unit?.number} isLoading={!Boolean(unit)} />
                </span>
              </div>

              <div className="col-md-12">
                <h4>Complemento</h4>
                <span>
                  <DataLoader
                    data={unit?.complement}
                    isLoading={!Boolean(unit)}
                  />
                </span>
              </div>

              <div className="col-md-12">
                <h4>Conveniências</h4>
                <span>
                  <DataLoader
                    data={unit?.conveniences
                      ?.map((it: Convenience) => it.name)
                      .join(', ')}
                    isLoading={!Boolean(unit)}
                  />
                </span>
              </div>
              <div className="col-md-12">
                <h4>Centro comercial</h4>
                <span>
                  <DataLoader
                    data={(unit?.shoppingCenter as SelectOptions)?.label}
                    isLoading={!Boolean(unit)}
                  />
                </span>
              </div>

              <div className="col-md-12">
                <hr className="pb-s-100" />
                <h4>Horários de funcionamento</h4>
                <div className="">
                  <DataLoader
                    data={unit?.openTimes?.map((it: OpenTimes, index) => (
                      <>
                        {index !== 0 && <hr className="mt-s-100" />}
                        <div className="row pt-s-100">
                          <div className="col-md-2">
                            <h5>Hora Inicial</h5>
                            <span>{it.start}</span>
                          </div>
                          <div className="col-md-2">
                            <h5>Hora final</h5>
                            <span>{it.end}</span>
                          </div>
                        </div>
                        <div className="row pt-s-100">
                          <div className="col-md-12">
                            <h5>Dias</h5>
                            <div className="d-flex grid-gap-1">
                              {Object.values(WeekDays).map((day) => {
                                const classNames = ['circular-day'];

                                if (
                                  it.days
                                    .map(
                                      (day_selected) =>
                                        WeekDays[
                                          day_selected as keyof typeof WeekDays
                                        ]
                                    )
                                    .includes(day)
                                ) {
                                  classNames.push('day-active');
                                }

                                function formatWeekDays(day: string) {
                                  return day === WeekDays.holiday
                                    ? day
                                    : day.charAt(0).toUpperCase();
                                }

                                return (
                                  <div
                                    className={classNames.join(' ')}
                                    title={day}
                                  >
                                    {formatWeekDays(day)}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                    isLoading={!Boolean(unit)}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <hr className="pb-s-100" />
                <h4>Contatos</h4>
                <div className="">
                  <DataLoader
                    isLoading={!Boolean(unit)}
                    data={unit?.contacts?.map((it, index) => (
                      <>
                        {index !== 0 && <hr className="mt-s-100" />}
                        <div className="row pt-s-100">
                          <div className="col-md-6">
                            <h5>Nome</h5>
                            <span>{it.name}</span>
                          </div>
                          <div className="col-md-6">
                            <h5>Email</h5>
                            <span>{it.email}</span>
                          </div>
                        </div>
                        <div className="row pt-s-100">
                          <div className="col-md-6">
                            <h5>Telefone</h5>
                            <span>{it.phone}</span>
                          </div>
                          <div className="col-md-6">
                            <h5>Tipo</h5>
                            <span>
                              {(it.contactType as SelectOptions).label}
                            </span>
                          </div>
                        </div>
                      </>
                    ))}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      >
        <TableColumn fromKey="name" header="Nome" />
        <TableColumn
          fromKey="establishment"
          nestedOrder="name"
          header="Estabelecimento"
          render={({ name }) => name}
        />
        <TableColumn
          disableSorting
          fromKey="street"
          header="Endereço"
          render={(_, row) => {
            return `${row.state} - ${row.city} - ${row.district} - ${row.street}`;
          }}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id) => {
            return (
              <>
                <div className="d-flex">
                  <Button
                    className="mr-s-200"
                    weight="bold"
                    design="transparent"
                    prefixes={
                      <Icon>
                        {expandedId === id ? 'expand_less' : 'expand_more'}
                      </Icon>
                    }
                    onClick={() => {
                      if (expandedId === id) {
                        setUnit(null);
                        setExpandedId('');
                      } else {
                        setExpandedId(id);
                        fetchUnit(id);
                      }
                    }}
                  />
                  <PopOver
                    fixedContent={<Icon>more_horiz</Icon>}
                    togglableContent={
                      <PopOverMenu
                        menu={[
                          {
                            onClick: () => {
                              setIsOpenEstablishmentUnitModal(id);
                            },
                            show: handleDynamicPermission({
                              context: 'establishmentUnits',
                              resource: 'update',
                              unitUUID: id,
                            }),
                            content: 'Editar',
                          },
                          {
                            onClick: () => {
                              deleteEstablishmentUnit(id);
                            },
                            content: 'Excluir',
                            show: handleDynamicPermission({
                              context: 'establishmentUnits',
                              resource: 'delete',
                              unitUUID: id,
                            }),
                          },
                        ]}
                      />
                    }
                  />
                </div>
              </>
            );
          }}
        />
      </Table>
      {!!isOpenEstablishmentUnitModal && (
        <Modal
          isOpen={!!isOpenEstablishmentUnitModal}
          onClickAway={() => setIsOpenEstablishmentUnitModal('')}
        >
          <EstablishmentUnitsForm
            editId={isOpenEstablishmentUnitModal}
            onSave={() => {
              if (expandedId) fetchUnit(expandedId);
            }}
            closeModal={() => setIsOpenEstablishmentUnitModal('')}
          />
        </Modal>
      )}
    </>
  );
}
