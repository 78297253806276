import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  Button,
  Input,
  PasswordInput,
  RadioButton,
  RadioForm,
  SelectInput as Select,
  Switch,
} from '@components';
import { Group } from '@interfaces/Group';
import { User, UserType } from '@interfaces/User';
import AccessGroupAPICaller from '@services/api/accessGroups';
import MeAPICaller from '@services/api/me';
import UsersAPICaller from '@services/api/users';
import { formEmailPattern } from '@validations/email';
import { getArrayWithoutUndefinedItems } from 'utils/array';
import { userTypeEnumToTypeName } from 'utils/users';

import { EstablishmentAndUnitsSelect } from './EstablishmentAndUnitsSelect';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
  isMe?: boolean;
}

export interface OptionListItem {
  value: string;
  label?: string;
  disabled?: boolean;
}

interface DefaultValue {
  id: string | null;
  name: string;
  active: boolean;
  email: string;
  userType: UserType;
  establishments: Array<string>;
  establishmentUnits: Array<string>;
  password?: string;
  accessGroup?: { label: string; value: string };
}

const { createOrUpdate, fetchUser, updateEstablishmentsAndEstablishmentUnits } =
  UsersAPICaller;

const { update, fetchMe } = MeAPICaller;

const fetchDefaultValues = async ({
  isMe,
  editId,
}: Pick<Props, 'editId' | 'isMe'>): Promise<DefaultValue> => {
  if (!editId) {
    return {
      id: null,
      active: true,
      name: '',
      email: '',
      userType: UserType.Global,
      establishments: [],
      establishmentUnits: [],
      password: '',
    };
  }

  let fetchedUser: User;
  if (isMe) {
    fetchedUser = await fetchMe();
  } else {
    const response = await fetchUser(editId);
    fetchedUser = response.data.user;
  }

  const establishmentsValues = fetchedUser.establishmentAccess?.map(
    ({ establishmentId }) => establishmentId
  );

  const establishmentUnitsValues = fetchedUser.establishmentUnitAccess?.map(
    ({ unitId }) => unitId
  );

  return {
    id: fetchedUser.id || null,
    active: fetchedUser.active,
    name: fetchedUser.name,
    email: fetchedUser.email,
    userType: fetchedUser.userType,
    accessGroup: fetchedUser?.accessGroupId
      ? {
          label: fetchedUser!.accessGroup!.name!,
          value: fetchedUser!.accessGroup!.id!,
        }
      : undefined,
    establishments: establishmentsValues || [],
    establishmentUnits: establishmentUnitsValues || [],
  };
};

const UsersFormsBase = ({
  isMe,
  editId,
  closeModal,
  onSave,
  ...props
}: Props) => {
  const [groups, setGroups] = useState<Group[]>([]);

  const {
    register,
    setError,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isLoading, isSubmitting, defaultValues },
  } = useForm<DefaultValue>({
    defaultValues: async () => fetchDefaultValues({ isMe, editId }),
  });

  useEffect(() => {
    AccessGroupAPICaller.fetchGroups({ active: true }).then((values) =>
      setGroups(values.result)
    );
  }, []);

  const [selectedEstablishmentUnits, selectedEstablishments, userType] = watch([
    'establishmentUnits',
    'establishments',
    'userType',
  ]);

  const userIsEstablishment = useMemo(() => {
    return userType === UserType.Establishment;
  }, [userType]);

  const userIsGlobal = useMemo(() => {
    return userType === UserType.Global;
  }, [userType]);

  const handleOnSubmit = useCallback<SubmitHandler<DefaultValue>>(
    async (data) => {
      const { establishments, establishmentUnits, ...userData } = data;

      if (isMe) {
        return update<typeof userData>(userData, setError).then((res) => {
          if (res.data.errors) return;
          closeModal();
          onSave();
        });
      }

      const createOrUpdateResponse = await createOrUpdate<typeof userData>(
        userData,
        setError
      );

      if (createOrUpdateResponse.data.errors) return;
      if (!defaultValues) {
        return;
      }

      if (data.userType === UserType.Global) {
        if (data.accessGroup?.value) {
          const userId = userData.id || createOrUpdateResponse.data.user.id;
          await UsersAPICaller.setUserGroup(userId, data.accessGroup?.value);
        }

        closeModal();
        onSave();
        return;
      }

      const { errors: updatingEstablishmentsAndUnitsErrors } =
        await updateEstablishmentsAndEstablishmentUnits(
          userData.id || createOrUpdateResponse.data.user.id,
          {
            originalEstablishments: getArrayWithoutUndefinedItems(
              defaultValues.establishments || []
            ),
            originalEstablishmentUnits: getArrayWithoutUndefinedItems(
              defaultValues.establishmentUnits || []
            ),
            updatedEstablishments: establishments,
            updatedEstablishmentUnits: establishmentUnits,
          },
          setError
        );

      if (
        getArrayWithoutUndefinedItems(updatingEstablishmentsAndUnitsErrors)
          .length
      ) {
        return;
      }

      closeModal();
      onSave();
    },
    [closeModal, defaultValues, isMe, onSave, setError]
  );

  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="container p-s-200" ref={formRef}>
      <div className="modal-title">
        <h3>{editId ? 'Editar usuário' : 'Cadastrar usuário'}</h3>
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <form
        role="form"
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <div className="row grid-gap-1">
          <div className={`col-md-${!isMe ? '10' : '12'}`}>
            <Input
              disabled={isLoading}
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name', { required: 'Obrigatório' })}
            />
          </div>
          {!isMe && (
            <>
              <div className="col-md-2">
                <Switch
                  disabled={isLoading}
                  className="mt-s-500 float-right"
                  caption={watch('active', true) ? 'Ativo' : 'Inativo'}
                  error={errors.active?.message as string}
                  form={register('active', { value: true })}
                />
              </div>
            </>
          )}
        </div>
        <div className="row grid-gap-1">
          {!isMe && (
            <div className={`col-sm-12 col-md-${!editId ? '6' : '12'}`}>
              <Input
                disabled={isLoading}
                error={!!errors.email}
                caption={errors.email?.message as string}
                placeholder="E-mail"
                label="E-mail"
                form={register('email', {
                  required: 'Obrigatório',
                  ...formEmailPattern,
                })}
              />
            </div>
          )}
          {!editId && (
            <div className="col-sm-12 col-md-6">
              <PasswordInput
                disabled={isLoading}
                error={!!errors.password}
                caption={errors.password?.message as string}
                placeholder="Senha"
                label="Senha"
                form={register('password', {
                  required: 'Obrigatório',
                })}
              />
            </div>
          )}
        </div>
        {!isMe && (
          <div className="row grid-gap-1">
            <div className="col-sm-12">
              <label
                htmlFor="operationFilter"
                className="font-s-150 text-neutral-20 font-weight-bold mb-s-50 d-block"
              >
                Tipo do usuário
              </label>
              <RadioForm name="userType" form={register('userType')}>
                {[UserType.Global, UserType.Establishment].map((thisType) => {
                  return (
                    <RadioButton
                      key={thisType}
                      value={thisType}
                      label={userTypeEnumToTypeName(thisType)}
                    />
                  );
                })}
              </RadioForm>
            </div>
          </div>
        )}
        {Boolean(userIsGlobal && !isMe) && (
          <div className="row grid-gap-1">
            <div className="col-sm-12">
              <Select
                value={watch('accessGroup')}
                placeholder="Vincular a um grupo de acesso"
                disabled={isLoading}
                options={groups}
                onSelect={(value) => {
                  setValue('accessGroup', value);
                }}
                selectProps={{ menuPlacement: 'top' }}
                form={register('accessGroup')}
                error={!!errors.accessGroup}
                caption={errors.accessGroup?.message as string}
                fromKey="name"
                label="Grupo de acesso"
              />
            </div>
          </div>
        )}

        {userIsEstablishment && !isMe && (
          <div className="row grid-gap-1">
            <div className="col-sm-12">
              <EstablishmentAndUnitsSelect
                formRef={formRef}
                label="Vincular estabelecimentos e unidades"
                valueEstablishment={selectedEstablishments}
                valueEstablishmentUnits={selectedEstablishmentUnits}
                onChangeEstablishments={(value) => {
                  setValue('establishments', value);
                }}
                onChangeEstablishmentUnits={(value) => {
                  setValue('establishmentUnits', value);
                }}
              />
            </div>
          </div>
        )}
        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={closeModal}>
            Cancelar
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isSubmitting || isLoading}
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
};

const UserForms = memo(UsersFormsBase);

export default UserForms;
