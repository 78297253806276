import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import defaultImage from '@assets/defaultImage.png';
import { DataLoader, PageHeader, Tab } from '@components';
import { UserClient } from '@interfaces/UserClient';
import UserClientsAPICaller from '@services/api/userClients';
import { validateUUIDFormat } from 'utils/string/uuid';

import { UserClientsDevices } from './UserClientsDevices/UserClientsDevices';
import { UserClientsInfo } from './UserClientsInfo/UserClientsInfo';
import { UserClientsPlans } from './UserClientsPlans/UserClientsPlans';
import { UserClientsVouchers } from './UserClientsVouchers/UserClientsVouchers';
import { UserClientsPhysicalVouchers } from './UserClientsPhysicalVouchers/UserClientsPhysicalVouchers';

export default function UserClientsView() {
  const { uuid } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [data, setData] = useState<UserClient | null>(null);

  const fetch = (uuid: string) => {
    UserClientsAPICaller.fetch(uuid).then((values) =>
      setData(values as UserClient)
    );
  };

  useEffect(() => {
    if (uuid) {
      if (validateUUIDFormat(uuid)) {
        fetch(uuid);
      } else {
        toast.error('ID informado inválido');
        navigate(-1);
      }
    }
  }, [uuid, navigate]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader backButton title="Usuário - Cliente" />

        <div className="row">
          <div className="col-md-2 mb-s-150">
            <DataLoader
              data={
                <img
                  src={data?.imageUrl || defaultImage}
                  alt="logo"
                  height={120}
                  className="border-radius-100"
                />
              }
              height={120}
              isLoading={!data}
            />
          </div>
          <div className="col-md-10 align-center">
            <div className="col-md-12">
              <h4>Nome</h4>
              <span>
                <DataLoader data={data?.name} isLoading={!data} />
              </span>
            </div>
            <div className="col-md-12 mt-s-150">
              <h4>Telefone</h4>
              <span>
                <DataLoader data={data?.phone} isLoading={!data} />
              </span>
            </div>
          </div>
        </div>

        <Tab
          tabSelected={Number(searchParams?.get('tab')) || 0}
          tabs={[
            {
              component: (
                <UserClientsInfo
                  data={data}
                  uuid={uuid}
                  fetch={() => fetch(uuid!)}
                />
              ),
              title: 'Informações',
            },
            {
              component: <UserClientsPlans uuid={uuid} />,
              title: 'Planos',
            },
            {
              component: <UserClientsVouchers uuid={uuid} />,
              title: 'Vouchers usados',
            },
            {
              component: <UserClientsPhysicalVouchers uuid={uuid} />,
              title: 'Vouchers físicos',
            },
            {
              component: <UserClientsDevices uuid={uuid} />,
              title: 'Aparelhos',
            },
          ]}
        />
      </div>
    </>
  );
}
