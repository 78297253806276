import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { geocodeByAddress } from 'react-places-autocomplete';

import {
  Button,
  Checkbox,
  Icon,
  Input,
  Map,
  SelectInput as Select,
} from '@components';
import { OptionWithTooltip } from '@components/Select/OptionWithTooltip';
import { UserSelect } from '@components/User/Select';
import { useAuthContext } from '@contexts/AuthProvider';
import { useFetchEstablishment } from '@hooks/establishment/useFetchEstablishments';
import { useGeolocation } from '@hooks/useGeolocation';
import {
  EstablishmentUnit,
  EstablishmentUnitContactType,
  EstablishmentUnitStatus,
  WeekDays,
} from '@interfaces/EstablishmentUnit';
import { Region } from '@interfaces/Region';
import { UserType } from '@interfaces/User';
import { SelectOptions } from '@interfaces/Utils';
import ConvenienceAPICaller from '@services/api/conveniences';
import EstablishmentUnitAPICaller from '@services/api/establishmentUnit';
import GeneralAPICaller from '@services/api/general';
import RegionAPICaller from '@services/api/regions';
import ShoppingCenterAPICaller from '@services/api/shoppingCenters';
import StatesAPICaller from '@services/api/states';
import Tippy, { useSingleton } from '@tippyjs/react';
import { formEmailPattern } from '@validations/email';
import { formHourPattern } from '@validations/hour';
import { formZipCodePattern } from '@validations/zipCode';
import { followCursor } from 'tippy.js';
import { getArrayWithoutUndefinedItems } from 'utils/array';
import { toValueLabel } from 'utils/object';
import { handleSelectedAll } from 'utils/hasSelectedAll';
import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import { onlyNumber } from 'utils/number';
import { formCPFPattern } from '@validations/cpf';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
  establishmentId?: string;
}

export default function EstablishmentUnitUnitsForm({
  establishmentId,
  ...props
}: Props) {
  const { createOrUpdate, fetch } = EstablishmentUnitAPICaller;
  const { user } = useAuthContext();

  const { getCurrentLocation, latLng } = useGeolocation();
  const [states, setStates] = useState<
    (SelectOptions & { abbreviation: string; name: string })[]
  >([]);
  const [cities, setCities] = useState<(SelectOptions | string)[]>([]);
  const [district, setDistrict] = useState<SelectOptions[] | string[]>([]);
  const [conveniences, setConveniences] = useState<SelectOptions[]>([]);
  const [shoppingCenters, setShoppingCenters] = useState<SelectOptions[]>([]);
  const [isZipCodeLoading, setIsZipCodeLoading] = useState(false);

  const [tippySource, tippyTarget] = useSingleton();

  const { establishment, fetchEstablishment } = useFetchEstablishment();

  const {
    register,
    setError,
    handleSubmit,
    watch,
    setValue,
    setFocus,
    control,
    clearErrors,
    formState: { errors, isSubmitting, isLoading, defaultValues },
  } = useForm<
    FieldValues & {
      contacts: [
        {
          name: string;
          email: string;
          phone: string;
          contactType: string;
          cpf: string;
        }
      ];
      openTimes: [{ start: string; end: string; days?: string }];
      usersWithAccess: Array<string>;
    }
  >({
    defaultValues: async () => fetchData(),
  });

  const {
    fields: FieldsOpenTimes,
    remove: removeOpenTimes,
    append: appendOpenTimes,
  } = useFieldArray({
    control,
    name: 'openTimes',
  });

  const {
    fields: FieldsContacts,
    remove: removeContacts,
    append: appendContacts,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const fetchConveniences = async () => {
    const result = await ConvenienceAPICaller.all();

    result.unshift({
      id: 'all',
      name: 'Todos',
    });

    setConveniences(result);
  };

  const fetchShoppingCenters = async () => {
    const result = await ShoppingCenterAPICaller.all();

    setShoppingCenters(result);
  };

  const fetchStates = async () => {
    const statesResponse = await StatesAPICaller.fetchStates();
    setStates(statesResponse);

    return states;
  };

  const fetchStateCities = async (stateName: string) => {
    setFocus('city');
    const cities = await StatesAPICaller.fetchStateCities(stateName);
    setCities(cities);

    return cities;
  };

  const fetchDistrict = async (state: string, city: string) => {
    setFocus('district');
    const result: Region[] = await RegionAPICaller.all({
      state,
      city,
    });

    setDistrict(result.map((region) => region.district));

    return result;
  };

  const fetchData = async () => {
    fetchStates();
    fetchConveniences();
    fetchShoppingCenters();

    if (props.editId) {
      const establishmentUnit = await fetch<EstablishmentUnit>(props.editId);

      await fetchStateCities(establishmentUnit.state);
      await fetchDistrict(establishmentUnit.state, establishmentUnit.city);

      setFocus('');

      return {
        ...establishmentUnit,
        status: toValueLabel(establishmentUnit.status),
        usersWithAccess:
          establishmentUnit.usersWithAccess?.map(({ userId }) => userId) || [],
      };
    }

    getCurrentLocation();

    return {
      status: toValueLabel(EstablishmentUnitStatus.active),
      openTimes: [
        {
          start: '',
          end: '',
        },
      ],
      contacts: [
        {
          name: '',
          email: '',
          phone: '',
          contactType: '',
          cpf: '',
        },
      ],
      usersWithAccess: [],
    };
  };

  const userFilter: ComponentPropsWithoutRef<typeof UserSelect>['usersFilter'] =
    useMemo(() => {
      return { userType: UserType.Establishment, active: true };
    }, []);

  useEffect(() => {
    setValue('latitude', latLng.latitude);
    setValue('longitude', latLng.longitude);
  }, [latLng, setValue]);

  const fetchZipCode = async (zipCode: string) => {
    setIsZipCodeLoading(true);
    setValue('state', '');
    setValue('city', '');
    setValue('district', '');
    setValue('street', '');
    const result = await GeneralAPICaller.searchZipCode(zipCode);

    if (result.localidade && result.uf) {
      const state = states.filter(
        (state) => state.abbreviation === result.uf
      )[0];

      setValue('state', toValueLabel(state.name));
      await fetchStateCities(state.name);

      setValue('city', toValueLabel(result.localidade));

      const districtList = await fetchDistrict(state.name, result.localidade);

      if (result.bairro) {
        const districtSearch = districtList.filter(
          (it) => it.district === result.bairro
        )[0];

        setValue('district', {
          ...districtSearch,
          value: districtSearch.district,
          label: districtSearch.district,
        });
      }

      if (result.logradouro) {
        setValue('street', result.logradouro);
      }
    }

    fetchGeoLocation();
    setIsZipCodeLoading(false);
  };

  const fetchGeoLocation = async () => {
    const address = [
      watch('state')?.label,
      watch('city')?.label,
      watch('district')?.label,
      watch('street'),
      watch('number'),
    ];

    const result = await geocodeByAddress(address.join(', '));

    if (result && Array.isArray(result) && result.length >= 1) {
      setValue('latitude', result.at(0)?.geometry?.location?.lat());
      setValue('longitude', result.at(0)?.geometry?.location?.lng());
    }
  };

  useEffect(() => {
    if (establishmentId) {
      fetchEstablishment(establishmentId);
    }
  }, [establishmentId, fetchEstablishment]);

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar ' : 'Cadastrar '}unidade</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit(async ({ usersWithAccess, ...data }) => {
          data.establishmentId = establishmentId;

          data.conveniences = handleSelectedAll(
            data.conveniences,
            conveniences
          );

          const response = await createOrUpdate<typeof data>(data, setError);

          if (response.data.hasOwnProperty('errors')) return;

          const {
            data: { unit },
          } = response as { data: { unit: EstablishmentUnit } };

          const { errors } = await EstablishmentUnitAPICaller.updateUsersAccess(
            unit.id!,
            getArrayWithoutUndefinedItems(defaultValues?.usersWithAccess || []),
            usersWithAccess,
            setError
          );

          if (errors.length) return;

          props.closeModal();
          props.onSave();
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Input
              disabled={isLoading}
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome da unidade"
              placeholder="Unidade"
              form={register('name')}
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('status')}
              placeholder="Status da unidade"
              disabled={isLoading}
              options={Object.values(EstablishmentUnitStatus)}
              onSelect={(value) => setValue('status', value)}
              form={register('status', { required: 'Obrigatório' })}
              error={!!errors.status}
              caption={errors.status?.message as string}
              label="Status"
            />
          </div>

          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.instagram}
              caption={errors.instagram?.message as string}
              label="Instagram"
              placeholder="@instagram"
              form={register('instagram')}
            />
          </div>
        </div>
        <div className="col-md-12 pt-s-200">
          <hr className="pb-s-200" />
          <h4>Localização</h4>
        </div>

        <div className="row grid-gap-1">
          <div className="col-md-6">
            <Input
              mask="zipCode"
              disabled={isLoading}
              error={!!errors.zipCode}
              caption={errors.zipCode?.message as string}
              label="CEP"
              placeholder="CEP"
              form={register('zipCode', {
                onChange: (event) => {
                  const zipCode = event.target.value as string;
                  if (zipCode.length === 9) {
                    fetchZipCode(zipCode);
                  }
                },
                required: 'Obrigatório',
                ...formZipCodePattern,
              })}
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('state')}
              placeholder="Estado"
              disabled={isLoading || isZipCodeLoading}
              isSearchable
              options={states}
              onSelect={(value: { label: string }) => {
                setValue('state', value);
                if (value?.label) {
                  setValue('city', '');
                  fetchStateCities(value?.label);
                }
              }}
              form={register('state', { required: 'Obrigatório' })}
              error={!!errors.state}
              caption={errors.state?.message as string}
              fromKey="name"
              label="Estado"
            />
          </div>
          <div className="col-md-6">
            <Select
              isSearchable
              value={watch('city')}
              placeholder="Cidade"
              disabled={isLoading || !watch('state') || isZipCodeLoading}
              options={cities}
              onSelect={(value) => {
                setValue('city', value);
                if (value.label) {
                  setValue('district', '');
                  fetchDistrict(watch('state').label, value.label);
                }
              }}
              form={register('city', { required: 'Obrigatório' })}
              error={!!errors.city}
              caption={errors.city?.message as string}
              label="Cidade"
            />
          </div>
          <div className="col-md-6">
            <Select
              isSearchable
              value={watch('district')}
              placeholder="Bairro"
              disabled={isLoading || !watch('city') || isZipCodeLoading}
              options={district as SelectOptions[]}
              onSelect={(value) => {
                setValue('district', value);
              }}
              form={register('district', { required: 'Obrigatório' })}
              error={!!errors.district}
              caption={errors.district?.message as string}
              label="Bairro"
            />
          </div>

          <div className="col-md-12">
            <Input
              disabled={isLoading || isZipCodeLoading}
              error={
                !!errors.street ||
                !!errors['name, state, city, district, street, number']
              }
              caption={
                (errors.street?.message as string) ||
                (errors['name, state, city, district, street, number']
                  ?.message as string)
              }
              label="Rua"
              placeholder="Rua"
              form={register('street', {
                required: 'Obrigatório',
                onChange: () => {
                  clearErrors('name, state, city, district, street, number');
                },
              })}
            />
          </div>

          <div className="col-md-6">
            <Input
              disabled={isLoading || isZipCodeLoading}
              error={!!errors.number}
              caption={errors.number?.message as string}
              label="Número"
              placeholder="Número"
              form={register('number', {
                required: 'Obrigatório',
                onBlur: () => {
                  fetchGeoLocation();
                },
              })}
            />
          </div>
          <div className="col-md-6">
            <Input
              disabled={isLoading || isZipCodeLoading}
              error={!!errors.complement}
              caption={errors.complement?.message as string}
              label="Complemento"
              placeholder="Complemento"
              form={register('complement')}
            />
          </div>

          <div className="col-md-12">
            <Select
              isSearchable
              isClearable
              value={watch('shoppingCenter')}
              placeholder="Shopping"
              disabled={isLoading}
              options={shoppingCenters}
              onSelect={(value) => {
                setValue('shoppingCenter', value);
              }}
              form={register('shoppingCenter')}
              error={!!errors.shoppingCenter}
              caption={errors.shoppingCenter?.message as string}
              label="Centro comercial"
              fromKey="name"
            />
          </div>

          <div className="col-md-12">
            <Map
              mapWidth="45vw"
              value={{
                lat: watch('latitude'),
                lng: watch('longitude'),
              }}
              onChange={(coords) => {
                setValue('latitude', coords.lat);
                setValue('longitude', coords.lng);
              }}
            />
          </div>

          <div className="col-md-12">
            <Select
              isSearchable
              isMulti
              isClearable
              value={watch('conveniences')}
              placeholder="Pet-friendly, estacionamento, aceita takeaway...."
              disabled={isLoading}
              options={conveniences}
              onSelect={(value) => {
                setValue('conveniences', value);
              }}
              form={register('conveniences')}
              error={!!errors.conveniences}
              caption={errors.conveniences?.message as string}
              label="Conveniências"
              fromKey="name"
            />
          </div>
        </div>

        <div>
          <div className="col-md-12 pt-s-200">
            <hr className="pb-s-200" />
            <h4>Horário de funcionamento</h4>
          </div>

          {FieldsOpenTimes.map((_, index: number) => (
            <div className="row grid-gap-1" key={`openTimes_${index}`}>
              <div className="col-md-5">
                <Input
                  mask="hour"
                  key={`startHour_${index}`}
                  disabled={isLoading}
                  error={!!errors.openTimes?.[index]?.start}
                  caption={errors.openTimes?.[index]?.start?.message as string}
                  label="Hora inicial"
                  placeholder="Ex:. 07:00"
                  form={register(`openTimes.${index}.start`, {
                    required: 'Obrigatório',
                    ...formHourPattern,
                  })}
                />
              </div>
              <div className="col-md-5">
                <Input
                  disabled={isLoading}
                  mask="hour"
                  key={`endHour_${index}`}
                  error={!!errors.openTimes?.[index]?.end}
                  caption={errors.openTimes?.[index]?.end?.message as string}
                  label="Hora final"
                  placeholder="Ex:. 23:00"
                  form={register(`openTimes.${index}.end`, {
                    required: 'Obrigatório',
                    ...formHourPattern,
                  })}
                />
              </div>

              <div
                className="col-md-2 d-flex justify-center pr-s-100"
                style={{ alignItems: 'end', marginRight: 0 }}
              >
                <Button
                  title="Remover horário"
                  color="negative"
                  key={`remove_${index}`}
                  design="outlined"
                  disabled={FieldsOpenTimes.length === 1}
                  onClick={() => removeOpenTimes(index)}
                  prefixes={<Icon>delete</Icon>}
                ></Button>
              </div>

              <div
                className="col-md-8"
                style={{
                  display: 'grid',
                  gridTemplateColumns: '50% 50% 50%',
                  gap: 16,
                }}
              >
                {Object.entries(WeekDays).map(([field, value]) => (
                  <Checkbox
                    key={`${field}_${value}`}
                    label={value}
                    form={register(`openTimes.${index}.${field}`)}
                  />
                ))}
              </div>

              {!!errors.openTimes?.[index]?.days && (
                <span className="text-left form-input__caption form-input__caption--error">
                  {errors.openTimes?.[index]?.days?.message as string}
                </span>
              )}

              {index !== FieldsOpenTimes.length - 1 && (
                <div className="col-md-12">
                  <hr />
                </div>
              )}
            </div>
          ))}

          <div className="col-md-12 pt-s-200">
            <h5 className="d-flex align-items-center justify-center">
              <hr
                className="mr-s-200"
                style={{
                  flex: 1,
                }}
              />
              <Button
                onClick={() => {
                  appendOpenTimes({
                    start: '',
                    end: '',
                  });
                }}
                suffixes={<Icon>add</Icon>}
                design="outlined"
              >
                Adicionar mais um horário
              </Button>
              <hr
                className="ml-s-200"
                style={{
                  flex: 1,
                }}
              />
            </h5>
          </div>
        </div>

        <div>
          <div className="col-md-12 pt-s-200">
            <h4>Contatos da unidade</h4>
          </div>

          {FieldsContacts.map((_, index: number) => (
            <div className="row grid-gap-1" key={`contact_${index}`}>
              <div className="col-md-5">
                <Input
                  key={`contact_name_${index}`}
                  disabled={isLoading}
                  error={!!errors.contacts?.[index]?.name}
                  caption={errors.contacts?.[index]?.name?.message as string}
                  label="Nome"
                  placeholder="Nome"
                  form={register(`contacts.${index}.name`)}
                />
              </div>
              <div className="col-md-5">
                <Input
                  key={`contact_email_${index}`}
                  disabled={isLoading}
                  error={!!errors.contacts?.[index]?.email}
                  caption={errors.contacts?.[index]?.email?.message as string}
                  label="Email"
                  placeholder="Email"
                  form={register(`contacts.${index}.email`, {
                    ...formEmailPattern,
                  })}
                />
              </div>

              <div
                className="col-md-2 d-flex justify-center pr-s-100"
                style={{ alignItems: 'end', marginRight: 0 }}
              >
                <Button
                  key={`contact_remove_${index}`}
                  title="Remover contato"
                  color="negative"
                  design="outlined"
                  onClick={() => removeContacts(index)}
                  prefixes={<Icon>delete</Icon>}
                />
              </div>

              <div className="col-md-4">
                <Input
                  key={`contact_phone_${index}`}
                  mask="phone"
                  disabled={isLoading}
                  error={!!errors.contacts?.[index]?.phone}
                  caption={errors.contacts?.[index]?.phone?.message as string}
                  label="Telefone"
                  placeholder="Telefone"
                  form={register(`contacts.${index}.phone`, {
                    validate: {
                      cellOrHome: (value) => {
                        if (!value) return true;
                        const isCell = /^\(\d{2}\) \d{5}-\d{4}$/.test(value);
                        const isHome = /^\(\d{2}\) \d{4}-\d{4}$/.test(value);

                        return isCell || isHome || 'Telefone inválido!';
                      },
                    },
                  })}
                />
              </div>

              <div className="col-md-4">
                <Select
                  key={`contact_type_${index}`}
                  isClearable
                  onSelect={(value: string) => {
                    setValue(`contacts.${index}.contactType`, value as never);
                  }}
                  value={watch(`contacts.${index}.contactType`)}
                  disabled={isLoading}
                  error={!!errors.contacts?.[index]?.contactType}
                  caption={errors.contacts?.[index]?.contactType?.message}
                  label="Tipo"
                  options={Object.values(EstablishmentUnitContactType)}
                  placeholder="Tipo"
                  form={register(`contacts.${index}.contactType`, {})}
                />
              </div>

              <div className="col-md-4">
                <Input
                  mask="cpf"
                  disabled={isLoading}
                  error={!!errors.contacts?.[index]?.cpf}
                  caption={errors.contacts?.[index]?.cpf?.message as string}
                  label="CPF"
                  placeholder="CPF"
                  form={register(`contacts.${index}.cpf`, {
                    ...formCPFPattern,
                    validate: {
                      cpf: (value) => {
                        const cpf = onlyNumber(value);

                        return !cpf || isValidCPF(value) || 'CPF inválido';
                      },
                    },
                  })}
                />
              </div>

              {index !== FieldsContacts.length - 1 && (
                <div className="col-md-12">
                  <hr />
                </div>
              )}
            </div>
          ))}

          <div className="col-md-12 pt-s-200">
            <h5 className="d-flex align-items-center justify-center">
              <hr
                className="mr-s-200"
                style={{
                  flex: 1,
                }}
              />
              <Button
                onClick={() => {
                  appendContacts({
                    name: '',
                    email: '',
                    contactType: '',
                    phone: '',
                    cpf: '',
                  });
                }}
                suffixes={<Icon>add</Icon>}
                design="outlined"
              >
                Adicionar contato
              </Button>
              <hr
                className="ml-s-200"
                style={{
                  flex: 1,
                }}
              />
            </h5>
          </div>

          {Boolean(!user || user?.userType === UserType.Global) && (
            <div className="col-md-12 mt-s-200">
              <h4>Acesso à unidade</h4>
              <Tippy
                singleton={tippySource}
                arrow={false}
                followCursor
                plugins={[followCursor]}
              />
              <UserSelect
                label="Usuários com acesso a esta unidade"
                value={watch('usersWithAccess')}
                onChange={(value) => {
                  setValue('usersWithAccess', value);
                }}
                usersFilter={userFilter}
                menuPosition="fixed"
                disabled={isLoading}
                error={!!errors.usersWithAccess}
                caption={errors.usersWithAccess?.message as string}
                getOptionLabel={({ name, email }) => `${name} - ${email}`}
                components={{
                  Option: (props) => (
                    <OptionWithTooltip
                      {...props}
                      tippyTarget={tippyTarget}
                      getTooltipText={() =>
                        `Concedido acesso ao estabelecimento e todas as unidades`
                      }
                    />
                  ),
                }}
                isOptionDisabled={(user) => {
                  if (!establishment) {
                    return false;
                  }

                  if (!establishment.usersWithAccess) {
                    return false;
                  }

                  return establishment.usersWithAccess.some(
                    (userWithAccess) => userWithAccess.userId === user.id
                  );
                }}
              />
            </div>
          )}
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button
            isLoading={isSubmitting || isLoading || isZipCodeLoading}
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
