import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from '@services/api';
import { Plan } from '@interfaces/Plan';
import { formatCurrency, formatValue } from 'utils/string/currency';
import { encodeDate, parseDate } from 'utils/string/dates';
import { toValueLabel } from 'utils/object';

import {
  PlanFilters,
  create,
  list,
  listAll,
  remove,
  retrieve,
  update,
} from './calls';

export * from './calls';

export default class PlanAPICaller {
  static adaptFromAPI = (data: Plan) =>
    ({
      ...data,
      startsAt: parseDate(data.startsAt),
      endsAt: parseDate(data.endsAt),
      value: formatCurrency(data.value as number),
      state: toValueLabel(data.state as string),
      vouchersAmount: data.vouchersAmount,
      city: toValueLabel(data.city as string),
    } as FieldValues);

  static adaptToAPI = (data: FieldValues) =>
    ({
      ...data,
      startsAt: encodeDate(data.startsAt),
      endsAt: encodeDate(data.endsAt),
      value: formatValue(data.value),
      state: data.state.value,
      vouchersAmount: Number(data.vouchersAmount),
      city: data.city.value,
    } as Plan);

  static list = async (filters: PlanFilters) => {
    const {
      data: { plans },
    } = await list(filters);

    return { ...plans, result: plans.result.map(this.adaptFromAPI) };
  };

  static all = async () => {
    const { data } = await listAll();
    return data.plans;
  };

  static fetch = async (editId: string) => {
    const { data } = await retrieve(editId);
    return this.adaptFromAPI(data.plan);
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
