import { FieldValues, UseFormSetError } from 'react-hook-form';

import { Establishment } from '@interfaces/Establishment';
import { User } from '@interfaces/User';
import { handleErrorForm } from '@services/api';
import { getArrayDifferences } from 'utils/array';

import EstablishmentAPICaller from '../establishment';

import {
  UserFilters,
  addEstablishment,
  addUnit,
  create,
  list,
  listUserEstablishments,
  removeEstablishment,
  removeGroup,
  removeUnit,
  retrieve,
  setAdmin,
  setGroup,
  update,
  updatePassword,
} from './calls';
export * from './calls';

export default class UsersAPICaller {
  static adaptFromAPI = (data: User) => data as FieldValues;
  static adaptToAPI = (data: FieldValues) => data as User;

  static fetchUsers = async (filters: UserFilters) => {
    const { data } = await list<{ users: { result: User[]; total: number } }>(
      filters
    );
    return data.users;
  };

  static fetchUser = async (editId: string) => {
    const response = await retrieve<{ user: User }>(editId);
    return response;
  };

  static createOrUpdate = async <T extends FieldValues>(
    data: T,
    setError: UseFormSetError<T>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static setUserPassword = async (
    userId: string,
    password: string,
    setError: UseFormSetError<FieldValues>
  ) => {
    const response = await updatePassword(userId, { password }).catch(
      handleErrorForm(setError)
    );
    return response;
  };

  static setUserGroup = async (userId: string, groupId: string) => {
    const response = await setGroup(userId, groupId);
    return response;
  };

  static removeUserGroup = async (userId: string) => {
    const response = await removeGroup(userId);
    return response;
  };

  static setAdmin = async (userId: string, params?: { admin?: boolean }) => {
    const response = await setAdmin(userId, params);
    return response;
  };

  static addEstablishments = async <T extends FieldValues>(
    userId: string,
    estabilshments: string[],
    setError: UseFormSetError<T>
  ) => {
    const promises = estabilshments.map((estabilshmentId) =>
      addEstablishment<{
        user: User;
      }>(userId, estabilshmentId).catch(handleErrorForm(setError))
    );

    return Promise.all(promises);
  };

  static removeEstablishments = async <T extends FieldValues>(
    userId: string,
    estabilshments: string[],
    setError: UseFormSetError<T>
  ) => {
    const promises = estabilshments.map((estabilshmentId) =>
      removeEstablishment(userId, estabilshmentId).catch(
        handleErrorForm(setError)
      )
    );

    return Promise.all(promises);
  };

  static addUnits = async <T extends FieldValues>(
    userId: string,
    units: string[],
    setError: UseFormSetError<T>
  ) => {
    const promises = units.map((unitId) =>
      addUnit(userId, unitId).catch(handleErrorForm(setError))
    );

    return Promise.all(promises);
  };

  static removeUnits = async <T extends FieldValues>(
    userId: string,
    units: string[],
    setError: UseFormSetError<T>
  ) => {
    const promises = units.map((unitId) =>
      removeUnit(userId, unitId).catch(handleErrorForm(setError))
    );

    return Promise.all(promises);
  };

  static updateEstablishments = async <T extends FieldValues>(
    userId: string,
    originalEstablishments: Array<string>,
    updatedEstablishments: Array<string>,
    setError: UseFormSetError<T>
  ) => {
    const {
      itemsAdded: establishmentsAdded,
      itemsRemoved: estabishmentsRemoved,
    } = getArrayDifferences(
      originalEstablishments,
      updatedEstablishments,
      false
    );

    const removingEstablishmentsErrors: Array<string | undefined> = [];
    const addingEstablishmentsErrors: Array<string | undefined> = [];

    const handleRemoveEstablishmentsErrors = (
      _: string,
      error: { message?: string }
    ) => {
      return removingEstablishmentsErrors.push(error.message);
    };

    await UsersAPICaller.removeEstablishments(
      userId,
      estabishmentsRemoved,
      handleRemoveEstablishmentsErrors
    );

    if (removingEstablishmentsErrors.length) {
      setError('establishments' as Parameters<typeof setError>[0], {
        message: 'Problema removendo um estabelecimento',
      });
    }

    const handleAddingEstablishmentsErrors = (
      _: string,
      error: { message?: string }
    ) => {
      return addingEstablishmentsErrors.push(error.message);
    };

    await UsersAPICaller.addEstablishments(
      userId,
      establishmentsAdded,
      handleAddingEstablishmentsErrors
    );

    if (addingEstablishmentsErrors.length) {
      setError('establishments' as Parameters<typeof setError>[0], {
        message: 'Problema adicionando um estabelecimento',
      });
    }

    return {
      errors: [...removingEstablishmentsErrors, ...addingEstablishmentsErrors],
    };
  };

  static updateEstablishmentUnits = async <T extends FieldValues>(
    userId: string,
    originalEstablishmentUnits: Array<string>,
    updatedEstablishmentUnits: Array<string>,
    setError: UseFormSetError<T>
  ) => {
    const {
      itemsAdded: establishmentUnitsAdded,
      itemsRemoved: estabishmentUnitsRemoved,
    } = getArrayDifferences(
      originalEstablishmentUnits,
      updatedEstablishmentUnits,
      false
    );

    const removingEstablishmentUnitsErrors: Array<string | undefined> = [];
    const addingEstablishmentUnitsErrors: Array<string | undefined> = [];

    const handleRemoveEstablishmentUnitsErrors = (
      _: string,
      error: { message?: string }
    ) => {
      return removingEstablishmentUnitsErrors.push(error.message);
    };

    await UsersAPICaller.removeUnits(
      userId,
      estabishmentUnitsRemoved,
      handleRemoveEstablishmentUnitsErrors
    );

    if (removingEstablishmentUnitsErrors.length) {
      setError('establishments' as Parameters<typeof setError>[0], {
        message: 'Problema removendo uma unidade',
      });
    }

    const handleAddingEstablishmentUnitsErrors = (
      _: string,
      error: { message?: string }
    ) => {
      return addingEstablishmentUnitsErrors.push(error.message);
    };

    await UsersAPICaller.addUnits(
      userId,
      establishmentUnitsAdded,
      handleAddingEstablishmentUnitsErrors
    );

    if (addingEstablishmentUnitsErrors.length) {
      setError('establishmentUnits' as Parameters<typeof setError>[0], {
        message: 'Problema adicionando uma unidade',
      });
    }

    return {
      errors: [
        ...removingEstablishmentUnitsErrors,
        ...addingEstablishmentUnitsErrors,
      ],
    };
  };

  static updateEstablishmentsAndEstablishmentUnits = async <
    T extends FieldValues
  >(
    userId: string,
    {
      originalEstablishments,
      updatedEstablishments,
      originalEstablishmentUnits,
      updatedEstablishmentUnits,
    }: {
      originalEstablishments: Array<string>;
      updatedEstablishments: Array<string>;
      originalEstablishmentUnits: Array<string>;
      updatedEstablishmentUnits: Array<string>;
    },
    setError: UseFormSetError<T>
  ) => {
    const { errors: establishmentsErrors } =
      await UsersAPICaller.updateEstablishments(
        userId,
        originalEstablishments,
        updatedEstablishments,
        setError
      );
    const { errors: establishmentUnitsErrors } =
      await UsersAPICaller.updateEstablishmentUnits(
        userId,
        originalEstablishmentUnits,
        updatedEstablishmentUnits,
        setError
      );

    return { errors: [...establishmentsErrors, ...establishmentUnitsErrors] };
  };

  static listEstablishments = async (userId: string) => {
    const {
      data: { establishments },
    } = await listUserEstablishments<{
      establishments: Array<Establishment>;
    }>(userId);

    return {
      total: establishments.length,
      result: await Promise.all(
        establishments.map((item: Establishment) => {
          const adaptedItem = EstablishmentAPICaller.adaptFromAPI(item);
          return adaptedItem;
        })
      ),
    };
  };
}
