import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  Button,
  Icon,
  MainContent,
  Restricted,
  Sidebar,
  SidebarButton,
} from '@components';
import SidebarGroup from '@components/Sidebar/SidebarGroup';
import { useAuthContext } from '@contexts/AuthProvider';

import { ProfileButton } from './ProfileButton';

export default function DefaultPage() {
  const navigate = useNavigate();
  const { user, logout, userIsEstablishment } = useAuthContext();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <div className="App">
        <header className="App-header">
          <div className="header--left">
            <div>
              <Button
                onClick={() => setCollapsed((previous) => !previous)}
                textClassName="d-flex align-items-center justify-center"
                color="secondary"
                design="transparent"
              >
                <Icon>menu</Icon>
              </Button>
            </div>
            <div className="d-flex pl-s-100 align-items-center">
              <Button
                textClassName="d-flex align-items-center justify-center"
                color="secondary"
                design="transparent"
                size="small"
                onClick={() => navigate('/')}
              >
                <img
                  src={require(`@assets/${process.env.REACT_APP_CITY}/icon.png`)}
                  alt="icon"
                  height={36}
                  style={{ margin: 0, padding: 0 }}
                />
              </Button>
              <span className="font-s-150">
                {process.env.REACT_APP_DASHBOARD_TITLE}
              </span>
            </div>
          </div>
          <div className="header--center">
            {/* <div className="!w-100">
              <Input
                placeholder="Procure por estabelecimentos, usuários, vouchers, cupons de desconto..."
                prefixes={<Icon className="pl-s-100">search</Icon>}
              />
            </div> */}
          </div>
          <div className="header--right">
            <ProfileButton logout={logout} user={user} />
          </div>
        </header>

        <Sidebar collapsed={collapsed} className="text-secondary">
          <SidebarGroup title="Dashboard">
            <Restricted context="report" resource="general">
              <SidebarButton
                label="Visão Geral"
                icon={<Icon>bar_chart</Icon>}
                onClick={() => navigate('/dashboard?report=general')}
              />
            </Restricted>
            <Restricted context="report" resource="operational">
              <SidebarButton
                label="Visão por Operação"
                icon={<Icon>bar_chart</Icon>}
                onClick={() => navigate('/dashboard?report=operational')}
              />
            </Restricted>
            {/* <Restricted context="report" resource="subscription">
              <SidebarButton
                label="Visão Assinaturas"
                icon={<Icon>bar_chart</Icon>}
                onClick={() => navigate('/dashboard?report=subscription')}
              />
            </Restricted> */}
          </SidebarGroup>
          <SidebarGroup title="Usuários">
            <Restricted context="userClients">
              <SidebarButton
                label="Usuários - Clientes"
                icon={<Icon>group</Icon>}
                onClick={() => navigate('/userClients')}
              />
            </Restricted>
            <Restricted context="userClients">
              <SidebarButton
                onClick={() => navigate('/devices')}
                label="Troca de aparelho"
                icon={<Icon>smartphone</Icon>}
              />
            </Restricted>
          </SidebarGroup>
          <SidebarGroup title="Gerenciamento">
            <SidebarButton
              onClick={() => navigate('/establishment')}
              label="Estabelecimento"
              icon={<Icon>location_on</Icon>}
            />

            {user?.userType === 'global' && (
              <Restricted context="establishmentUnits">
                <SidebarButton
                  onClick={() => navigate('/units')}
                  label="Unidades"
                  icon={<Icon>apartment</Icon>}
                />
              </Restricted>
            )}

            <Restricted context="vouchers">
              <SidebarButton
                onClick={() => navigate('/vouchers')}
                label="Vouchers"
                icon={<Icon>local_activity</Icon>}
              />
            </Restricted>

            <Restricted context="notifications">
              <SidebarButton
                onClick={() => navigate('/notifications')}
                label="Pushes"
                icon={<Icon>notifications</Icon>}
              />
            </Restricted>
            <Restricted context="advertisement">
              <SidebarButton
                label="Anúncios"
                icon={<Icon>campaign</Icon>}
                onClick={() => navigate('/advertisement')}
              />
            </Restricted>
            <Restricted context="discountCoupons">
              <SidebarButton
                label="Cupom de desconto"
                icon={<Icon>heap_snapshot_large</Icon>}
                onClick={() => navigate('/discountCoupons')}
              />
            </Restricted>
          </SidebarGroup>
          <SidebarGroup title="Acessos">
            <Restricted context="accessGroups">
              <SidebarButton
                label="Níveis de Acesso"
                icon={<Icon>device_hub</Icon>}
                onClick={() => navigate('/access')}
              />
            </Restricted>
            <Restricted context="users">
              <SidebarButton
                label="Acesso ao dashboard"
                icon={<Icon>mfg_nest_yale_lock</Icon>}
                onClick={() => navigate('/backoffice_users')}
              />
            </Restricted>
          </SidebarGroup>

          <SidebarGroup title="Cadastros">
            <Restricted context="clientAccessReasons">
              <SidebarButton
                label="Alteração de acesso"
                icon={<Icon>description</Icon>}
                onClick={() => navigate('/client_access_reasons')}
              />
            </Restricted>

            <Restricted context="faqs">
              <SidebarButton
                label="FAQ"
                icon={<Icon>help</Icon>}
                onClick={() => navigate('/faqs')}
              />
            </Restricted>
            <Restricted context="conveniences">
              <SidebarButton
                label="Conveniências"
                icon={<Icon>local_convenience_store</Icon>}
                onClick={() => navigate('/conveniences')}
              />
            </Restricted>
            <Restricted context="shoppingCenters">
              <SidebarButton
                label="Centro comercial"
                icon={<Icon>store</Icon>}
                onClick={() => navigate('/shopping_centers')}
              />
            </Restricted>
            <Restricted context="cooking">
              <SidebarButton
                label="Culinária"
                icon={<Icon>restaurant</Icon>}
                onClick={() => navigate('/cooking')}
              />
            </Restricted>
            <Restricted context="regions">
              <SidebarButton
                label="Regiões"
                icon={<Icon>south_america</Icon>}
                onClick={() => navigate('/regions')}
              />
            </Restricted>
            <Restricted context="benefits">
              <SidebarButton
                label="Benefícios"
                icon={<Icon>loyalty</Icon>}
                onClick={() => navigate('/benefits')}
              />
            </Restricted>
            <Restricted context="plans">
              <SidebarButton
                label="Planos"
                icon={<Icon>list_alt</Icon>}
                onClick={() => navigate('/plans')}
              />
            </Restricted>
            <Restricted context="categories">
              <SidebarButton
                label="Categoria"
                icon={<Icon>category</Icon>}
                onClick={() => navigate('/categories')}
              />
            </Restricted>
            <Restricted context="groups">
              <SidebarButton
                label="Grupos"
                icon={<Icon>table_view</Icon>}
                onClick={() => navigate('/groups')}
              />
            </Restricted>
            <Restricted context="tips">
              <SidebarButton
                label="Dicas"
                icon={<Icon>distance</Icon>}
                onClick={() => navigate('/tips')}
              />
            </Restricted>
            <Restricted context="loginBanner">
              <SidebarButton
                label="Banner do Login"
                icon={<Icon>photo_library</Icon>}
                onClick={() => navigate('/banner')}
              />
            </Restricted>
          </SidebarGroup>
          <SidebarGroup title="Relatórios - Tour Digital">
            {userIsEstablishment && (
              <Restricted
                context="report"
                resource="getUsageDataByUserEstablishmentReport"
              >
                <SidebarButton
                  label="Dados das utilizações"
                  icon={<Icon>phone_iphone</Icon>}
                  onClick={() => {
                    navigate('/report?report=usage_data_user_establishment');
                  }}
                />
              </Restricted>
            )}
            {userIsEstablishment && (
              <Restricted
                context="report"
                resource="getUsageDataByUnitEstablishmentReport"
              >
                <SidebarButton
                  label="Vouchers por unidade"
                  icon={<Icon>qr_code_scanner</Icon>}
                  onClick={() => {
                    navigate('/report?report=usage_data_by_unit_establishment');
                  }}
                />
              </Restricted>
            )}
            <Restricted context="report" resource="getUsageDataReport">
              <SidebarButton
                label="Dados das utilizações"
                icon={<Icon>phone_iphone</Icon>}
                onClick={() => {
                  navigate('/report?report=usage_data');
                }}
              />
            </Restricted>
            <Restricted context="report" resource="getUsageDataByUnitReport">
              <SidebarButton
                label="Vouchers por unidade"
                icon={<Icon>qr_code_scanner</Icon>}
                onClick={() => {
                  navigate('/report?report=usage_data_by_unit');
                }}
              />
            </Restricted>
            <Restricted
              context="report"
              resource="getUsageDataEstablishmentReport"
            >
              <SidebarButton
                label="Utilizações Estabele..."
                icon={<Icon>analytics</Icon>}
                onClick={() => {
                  navigate('/report?report=usage_data_establishment');
                }}
              />
            </Restricted>
            <Restricted context="report" resource="getDiscountCouponReport">
              <SidebarButton
                label="Cupom de desconto"
                icon={<Icon>heap_snapshot_thumbnail</Icon>}
                onClick={() => {
                  navigate('/report?report=discount_coupon');
                }}
              />
            </Restricted>
            <Restricted context="report" resource="getUsageByUserClientReport">
              <SidebarButton
                label="Utilização por cliente"
                icon={<Icon>person_play</Icon>}
                onClick={() => {
                  navigate('/report?report=usage_data_user_client');
                }}
              />
            </Restricted>
          </SidebarGroup>

          {userIsEstablishment && (
            <SidebarGroup title="Relatórios - Tour Físico">
              {userIsEstablishment && (
                <Restricted
                  context="report"
                  resource="getUsageDataByUserEstablishmentPhysicalReport"
                >
                  <SidebarButton
                    label="Dados das utilizações"
                    icon={<Icon>menu_book</Icon>}
                    onClick={() => {
                      navigate(
                        '/report?report=usage_data_user_establishment_physical'
                      );
                    }}
                  />
                </Restricted>
              )}
              {userIsEstablishment && (
                <Restricted
                  context="report"
                  resource="getUsageDataByUnitEstablishmentPhysicalReport"
                >
                  <SidebarButton
                    label="Vouchers por unidade"
                    icon={<Icon>auto_stories</Icon>}
                    onClick={() => {
                      navigate(
                        '/report?report=usage_data_by_unit_establishment_physical'
                      );
                    }}
                  />
                </Restricted>
              )}
            </SidebarGroup>
          )}
        </Sidebar>
        <MainContent collapsed={collapsed} className="bg-light-variant">
          <Outlet />
        </MainContent>
      </div>
    </>
  );
}
